import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Braese" mdxType="Columns">
      <p>{`Für den Auf- und Ausbau unserer Internetplattformen sowie zur Unterstützung unserer Kundenprojekte suchen wir einen multitalentierten Server- und Netzwerkadministrator mit Freude an der Herausforderung und Lust auf ein vielfältiges und begeistertes Team am Standort Berlin.`}</p>
      <h2>{`Deine Aufgaben`}</h2>
      <ul>
        <li parentName="ul">{`Ausbau und Pflege der IT-Infrastruktur unserer Internetplattformen sowie unserer internen Systeme in hoher Eigenverantwortung`}</li>
        <li parentName="ul">{`Unterstützung unserer Entwicklungsteams bei Infrastrukturthemen und bei der Einrichtung/Optimierung von CI/CD Prozessen`}</li>
        <li parentName="ul">{`Aufbau und Administration von Infrastruktur-Systemen, die in der Cloud (GCP, Azure, AWS etc.) oder auch „on premise“ betrieben werden`}</li>
      </ul>
      <h2>{`Dein Profil`}</h2>
      <ul>
        <li parentName="ul">{`Abgeschlossenes Studium im Bereich Informatik oder eine vergleichbare Ausbildung`}</li>
        <li parentName="ul">{`Kenntnisse und Erfahrungen bei der Administration von Linux basierten Serversystemen`}</li>
        <li parentName="ul">{`Kenntnisse im Bereich Containervirtualisierung mit Docker`}</li>
        <li parentName="ul">{`Selbständigkeit, Verantwortungsbewusstsein und Lösungsorientierung`}</li>
        <li parentName="ul">{`Kreativität und Teamfähigkeit`}</li>
      </ul>
      <p>{`Zu den folgenden Technologien und Konzepten sind uns Erfahrungen willkommen, aber auch eine ausgeprägte Neugier darauf sind ein guter Anfang:`}</p>
      <ul>
        <li parentName="ul">{`Container-Orchestrierung mit Kubernetes / Docker Swarm`}</li>
        <li parentName="ul">{`CI/CD, Konzeption und Implementierung von Pipelines`}</li>
        <li parentName="ul">{`Git`}</li>
        <li parentName="ul">{`Gitlab self hosted`}</li>
        <li parentName="ul">{`Ansible`}</li>
        <li parentName="ul">{`Terraform`}</li>
        <li parentName="ul">{`Webserver und Reverse-Proxy (Traefik, Nginx, Apache)`}</li>
        <li parentName="ul">{`relationale und NoSQL-Datenbanken`}</li>
      </ul>
      <h2>{`Unser Angebot`}</h2>
      <ul>
        <li parentName="ul">{`Ein interessanter, vielseitiger Arbeitsplatz mit einer langfristigen Perspektive und der Möglichkeit, durch Verantwortung und Gestaltungsfreiraum erfolgreich zu sein`}</li>
        <li parentName="ul">{`Aktive Mitarbeit in einem vielseitigen, dynamischen Team`}</li>
        <li parentName="ul">{`Freiraum, um innovative Ideen zu entwickeln und zu verwirklichen`}</li>
      </ul>
      <p>{`Wir freuen uns darauf, dich kennen zu lernen!`}</p>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      